td{
    padding: 30px 5px 5px 100px !important;
    text-align: left !important;
}
.card{
    margin:50px 50px 50px 100px;
    border-radius: 20px;
}






/*** FONTS ***/
/*** VARIABLES ***/
/* Colors */
/*** EXTEND ***/
/* box-shadow */
ol.gradient-list > li, ol.gradient-list > li::before {
    box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
}
ol.gradient-list > li:hover, ol.gradient-list > li:hover {
    box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem #0747a6a1;
}
/*** STYLE ***/
*, *:before, *:after {
    box-sizing: border-box;
}
main {
    display: block;
    margin: 0 auto;
    /* max-width: 40rem; */
    padding: 0.4rem 1rem;
}
ol.gradient-list {
    counter-reset: gradient-counter;
    list-style: none;
    margin: 1.75rem 0;
    padding-left: 1rem;
}
ol.gradient-list > li {
    background: white;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    counter-increment: gradient-counter;
    margin-top: 1rem;
    min-height: 3rem;
    padding: 1rem 1rem 1rem 3rem;
    position: relative;
}
ol.gradient-list > li::before, ol.gradient-list > li::after {
    background: linear-gradient(135deg, #0747a6 0%, #000000 100%);
    border-radius: 1rem 1rem 0 1rem;
    content: '';
    height: 3rem;
    left: -1rem;
    overflow: hidden;
    position: absolute;
    top: -1rem;
    width: 3rem;
}
ol.gradient-list > li::before {
    align-items: flex-end;
    /* content: counter(gradient-counter); */
    color: #1d1f20;
    display: flex;
    font: 900 1.5em/1 'Montserrat';
    justify-content: flex-end;
    padding: 0.125em 0.25em;
    z-index: 1;
}

ol.gradient-list > li:nth-child(10n+1):before {
    background-image: url('https://storage.googleapis.com/fargowiz/Webp.net-resizeimage%20(2).png');
    background-repeat:no-repeat ;
    background-position: center;   /* background:linear-gradient(135deg, rgb(7 71 166) 0%, rgb(0 0 0) 100%); */
}
ol.gradient-list > li:nth-child(10n+2):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.4) 0%, rgba(253, 220, 50, 0.4) 100%);
}
ol.gradient-list > li:nth-child(10n+3):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.6) 0%, rgba(253, 220, 50, 0.6) 100%);
}
ol.gradient-list > li:nth-child(10n+4):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.8) 0%, rgba(253, 220, 50, 0.8) 100%);
}
ol.gradient-list > li:nth-child(10n+5):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 1) 0%, rgba(253, 220, 50, 1) 100%);
}
ol.gradient-list > li:nth-child(10n+6):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.8) 0%, rgba(253, 220, 50, 0.8) 100%);
}
ol.gradient-list > li:nth-child(10n+7):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.6) 0%, rgba(253, 220, 50, 0.6) 100%);
}
ol.gradient-list > li:nth-child(10n+8):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.4) 0%, rgba(253, 220, 50, 0.4) 100%);
}
ol.gradient-list > li:nth-child(10n+9):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.2) 0%, rgba(253, 220, 50, 0.2) 100%);
}
ol.gradient-list > li:nth-child(10n+10):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0) 0%, rgba(253, 220, 50, 0) 100%);
}
ol.gradient-list > li + li {
    margin-top: 2rem;
}
