.trimmedVideodiv{
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 16px;
    padding: 5px;
    display: flex;
    flex-direction: column;
}
.trimmedTranscript{
    margin-top: 10px;
    padding: 3px;
}
.trimmedVideotitle{
    text-align: center;
    text-decoration: underline;
    text-transform: capitalize;
}