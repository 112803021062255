.wrapper {
    display: flex;
    width: 100%;
}

#sidebar {
    width: 70px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    background: #0747a6;
    color: #fff;
    transition: all 0.3s;
}

#sidebar {
    min-width: 70px;
    max-width: 250px;
}

#sidebar.active {
    margin-left: -250px;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}


@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";


body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 8px;
}

#sidebar {
 
    background: #0747a6;
    color: #fff;
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #0747a6;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: #fff;
}
#sidebar ul li a:hover {
    color: #fff;
    background: rgb(53, 90, 236);
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #042e6d;
}
ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
}


.topHeader{
    margin: 0 auto; 
     position: absolute !important;
    width: 165vh;
    top: 10px;
    right: 10px;
    
}
.topHeaderTitle{
    padding: 10px;
} 


.wrapper {
    display: flex;
    width: 100%;
}

#sidebar {
    
  
    height: 100vh;
    z-index: 999;
    background: #1A73E8;
    color: #fff;
    transition: all 0.3s;
}



#sidebar.active {
    margin-left: -250px;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}
.dropdown-container{
margin:0 30px 0 30px;
font-size: 14px;
}


@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";


body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 8px;
}

#sidebar {
    /* don't forget to add all the previously mentioned styles here too */
    background: #0747a6;
    color: #fff;
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #0747a6;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: #fff;
}
#sidebar ul li a:hover {
    color: #fff;
    background: rgb(53, 90, 236);
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #042e6d;
}
ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
}


.topHeader{
    /* margin: 0 auto; */
    position: absolute !important;
    width: 165vh;
    top: 10px;
    right: 10px;
    
}
.topHeaderTitle{
    padding: 10px;
}
.popup{
visibility: hidden;
width: 120px;
background-color: black;
color: #fff !important;
text-align: center;
border-radius: 6px;
display: inline-block;
padding: 4px 8px;
font-size: 14px;


/* Position the tooltip */
position: absolute;
z-index: 1;
}

.main:hover .popup {
visibility: visible;
}
.testtt{
height:0px ;
}