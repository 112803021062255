.homewrapper {
  margin-top: 1em;
}
.dashboardParent {
  width: 100%;
  /* height: 100%; */
  position: relative;
  /* padding-left: 260px; */
  /* padding-top: 10px; */
  background: #fafafa;

  margin: auto;

  left: 0;
  right: 0;
  /* top: 55px; */
  bottom: 0;
}

.card-header-sub {
  overflow: hidden;
  /* border-bottom: 1px solid #b6b6b6;
  border-right: 1px solid #b6b6b6;
  border-left: 1px solid #b6b6b6; */
  clear: both;
  background-color: #ffffff;
  padding: 0.6rem 0.25rem !important;
  cursor: pointer;
}

.top-header-category {
  overflow: hidden;
  border-bottom: 1px solid #d3d3d3;
  border-top: 1px solid #d3d3d3;
  clear: both;
  margin-top: 8px;
  margin-bottom: 8px;
}

.homeboxCardViewTop {
  background-color: #ffffff;
  text-align: center;
  float: left;
  /* // box-shadow: 0 3px 4px 0 rgba(0,0,0,.2), 0 3px 3px -2px rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12); */
  border: 1px solid #b6b6b6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: black;
}

.card-header-sub:hover {
  background-color: #d3d3d3;
}
.loadingBarCss {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.table-cell-css {
  display: table-cell;
  vertical-align: middle;
}
.tableCss {
  display: table;
  width: 100%;
  height: 90%;
}
.ContactPage {
  padding: 10px 15px;
}
.ContactPage .makeStyles-root-1.form-group,.ContactPage .form-group{display:inline-block;width:49%;margin:.5%;}
.ContactPage .txtAreaContct.makeStyles-root-1.form-group,.ContactPage .txtAreaContct.form-group{width:98%;}
.ContactPage .txtAreaContct.makeStyles-root-1.form-group,.ContactPage .txtAreaContct.form-group {width:98%;}
.page-title-section,
.page-id-70 .page-title-section {
  padding: 25px 0;
}
.contactForm .card {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  /* box-shadow: none !important; */
  /* margin: 0px !important; */
 
  border-radius: 25px !important;
  float: right;
  padding: 35px;
  border: 3px solid #ECECEC !important;
}
.uploadButton{
  border-radius: 30px !important;
  background-color: #0747a6;

}
.uploadButton:hover {
  border: #0747a6 solid 1px;
  color: #0747a6;
  background-color: #ffffff;

}
.contactForm .card-body {
  padding: 1.4rem 0.5rem 0.5rem 2.5rem !important;
  display: inline-block;
  margin-left: 120px;
}

.contactForm .card-title {
  text-align: center;
  color: #0747a6 !important;
  border-bottom:#ECECEC ;
  font-size: 2rem !important ;
  margin-top: 45px;
}
.btn:disabled{
background-color: rgba(0, 0, 0, 0.63);
color:rgb(131, 129, 129)
}
.ContactPage .MuiFormControl-root,.formParent .rightDiv{background-color: #fff;background: #ffffff;}    
.loading{
  display: inline-flex;
}

.formControl{
  width: 100%;
    height: 50px !important;
    padding: 0 20px;
    border-radius: 30px !important;
    color: #a6a6a6;
    font-size: 14px;
    
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.formControl:focus{
  border-radius: 30px !important;
  border-color: #1583e9 !important;
  outline: none;
}