@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
.homewrapper {
  margin-top: 1em;
}
.dashboardParent {
  width: 100%;
  /* height: 100%; */
  position: relative;
  /* padding-left: 260px; */
  /* padding-top: 10px; */
  background: #fafafa;

  margin: auto;

  left: 0;
  right: 0;
  /* top: 55px; */
  bottom: 0;
}

.card-header-sub {
  overflow: hidden;
  /* border-bottom: 1px solid #b6b6b6;
  border-right: 1px solid #b6b6b6;
  border-left: 1px solid #b6b6b6; */
  clear: both;
  background-color: #ffffff;
  padding: 0.6rem 0.25rem !important;
  cursor: pointer;
}

.top-header-category {
  overflow: hidden;
  border-bottom: 1px solid #d3d3d3;
  border-top: 1px solid #d3d3d3;
  clear: both;
  margin-top: 8px;
  margin-bottom: 8px;
}

.homeboxCardViewTop {
  background-color: #ffffff;
  text-align: center;
  float: left;
  /* // box-shadow: 0 3px 4px 0 rgba(0,0,0,.2), 0 3px 3px -2px rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12); */
  border: 1px solid #b6b6b6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: black;
}

.card-header-sub:hover {
  background-color: #d3d3d3;
}
.loadingBarCss {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.table-cell-css {
  display: table-cell;
  vertical-align: middle;
}
.tableCss {
  display: table;
  width: 100%;
  height: 90%;
}
.ContactPage {
  padding: 10px 15px;
}
.ContactPage .makeStyles-root-1.form-group,.ContactPage .form-group{display:inline-block;width:49%;margin:.5%;}
.ContactPage .txtAreaContct.makeStyles-root-1.form-group,.ContactPage .txtAreaContct.form-group{width:98%;}
.ContactPage .txtAreaContct.makeStyles-root-1.form-group,.ContactPage .txtAreaContct.form-group {width:98%;}
.page-title-section,
.page-id-70 .page-title-section {
  padding: 25px 0;
}
.contactForm .card {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  /* box-shadow: none !important; */
  /* margin: 0px !important; */
 
  border-radius: 25px !important;
  float: right;
  padding: 35px;
  border: 3px solid #ECECEC !important;
}
.uploadButton{
  border-radius: 30px !important;
  background-color: #0747a6;

}
.uploadButton:hover {
  border: #0747a6 solid 1px;
  color: #0747a6;
  background-color: #ffffff;

}
.contactForm .card-body {
  padding: 1.4rem 0.5rem 0.5rem 2.5rem !important;
  display: inline-block;
  margin-left: 120px;
}

.contactForm .card-title {
  text-align: center;
  color: #0747a6 !important;
  border-bottom:#ECECEC ;
  font-size: 2rem !important ;
  margin-top: 45px;
}
.btn:disabled{
background-color: rgba(0, 0, 0, 0.63);
color:rgb(131, 129, 129)
}
.ContactPage .MuiFormControl-root,.formParent .rightDiv{background-color: #fff;background: #ffffff;}    
.loading{
  display: -webkit-inline-flex;
  display: inline-flex;
}

.formControl{
  width: 100%;
    height: 50px !important;
    padding: 0 20px;
    border-radius: 30px !important;
    color: #a6a6a6;
    font-size: 14px;
    
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.formControl:focus{
  border-radius: 30px !important;
  border-color: #1583e9 !important;
  outline: none;
}
.wrapper {
  display: block;
  width: 90%;
  -webkit-align-items: stretch;
          align-items: stretch;
}

body {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

#topbar {
  /* don't forget to add all the previously mentioned styles here too */
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  z-index: 9;
  left: 0;
  color: #fff;
  transition: all 0.3s;
  width: 100%;
  margin: auto;
  position: relative;
}

#topbar .topbar-header {
  color: #0e0e0e;
  padding: 8px;
  background: #fafafa;
  /* box-shadow: 5px 5px 7px 1px #888888; */
  border: 1px solid #aeacac;
  box-shadow: 5px 5px 9px 1px #f1ebeb;
}

#topbar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #47748b;
}

#topbar ul p {
  color: #fff;
  padding: 10px;
}

#topbar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}
#topbar ul li a:hover {
  color: #7386d5;
  background: #fff;
}

#topbar .topbar-header h3 {
  text-align: center;
}

#topbar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}
ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}
.fullheight {
  height: 100%;
}

.app {
  width: 50%;
  margin: 0 auto;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  color: #4a4a4d;
  font: 14px/1.4 "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 100%;
}
tr {
  overflow-x: scroll;
}
th,
td {
  padding: 5px 10px;
  vertical-align: middle;
  text-align: center;
}
thead {
  font-size: 14px;
  line-height: 24px;
  font-family: Lato;
  border: 1px solid transparent;
  width: 100%;
  max-width: 100%;
  font-weight: 900;
  line-height: 24px;
  mix-blend-mode: normal;

  color: rgba(51, 51, 51, 0.5);
  background: #f0f0f2;
}
thead tr th {
  padding: 5px 10px;
  border: 1px solid transparent;
  color: #000000;
  position: sticky;
  top: 0;
  position: -webkit-sticky;
  background: #f0f0f2;
  font-family: sans-serif;
  letter-spacing: 1.4px;

  text-align: center;
}
tbody tr {
  cursor: pointer;
}
tbody {
  max-width: 100%;
}
tbody tr:nth-child(even) {
  background: #f0f0f2;
}
tbody tr:nth-child(even) {
  background: #ffffff;
}
td {
  padding: 5px 10px;
}
td:first-child {
}
@media screen and (max-width: 767px) {
  .chartClass {
    width: 90% !important;
    float: none !important;
    margin: 2% auto;
  }
}

@media screen and (max-width: 798px) {
  .peoplecount {
    width: 90% !important;
    float: none !important;
    margin-left: 1em;
  }
}
@media screen and (max-width: 798px) {
  .peoplecountinside {
    text-align: center;
    -webkit-margin-before: 1em !important;
            margin-block-start: 1em !important;
    -webkit-margin-after: 0em !important;
            margin-block-end: 0em !important;
    -webkit-margin-end: -1em !important;
            margin-inline-end: -1em !important;
    width: 100% !important;
  }
}
@media screen and (max-width: 798px) {
  #topbar {
    text-align: center;
  }
}
@media screen and (max-width: 798px) {
  .topbar-header {
    float: none !important;
    padding-top: 30px !important;
  }
}
@media screen and (max-width: 798px) {
  .homedetail {
    text-align: center !important;
  }
}
@media screen and (max-width: 798px) {
  .homedetail-div {
  }
}

/* .homebox1{
  cursor: pointer;
width: 25%;
    background-color: #e2e2e2;
    text-align: center;
    float: left;
    margin-left: 0.6em;
    padding: 5px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.2), 0 3px 3px -2px rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12);
    border-radius: 5px;

} */
.homebox2 {
  width: 25%;
  background-color: #e2e2e2;
  text-align: center;
  float: left;
  margin-left: 0.6em;
  padding: 5px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.homeboxnumber {
  font-weight: bold;
  font-size: 2em;
}

/* @media screen and (max-width: 798px) {
    .homebox1  {
    
      float: none;
      width: 95%;
      margin: 10px auto;
      
      
      
    }
  } */
@media screen and (max-width: 798px) {
  .homebox2 {
    float: none;
    width: 95%;
    margin: 4px auto;
  }
}

@media screen and (max-width: 798px) {
  .main-wrapper {
    padding-top: 104px !important;
  }
}
@media screen and (max-width: 798px) {
  .table-question-option {
    padding-top: 0px !important;
    margin: auto 5% !important;
  }
}

/* .homebox1:hover,.homebox2:hover, .homebox1:active, .homebox2:active, .homebox1:visited, .homebox2:visited{
    background-color: #F0F0F2;
    transition: 0.2s ease-in-out;
  }
    .homebox1:hover .homeboxnumber,.homebox1:hover  .homeboxtitle, .homebox2:hover .homeboxnumber,.homebox2:hover  .homeboxtitle{color:#ffffff;} */

@media screen and (max-width: 502px) {
  .main-wrapper {
    padding-top: 133px !important;
  }
}

.ReactTable {
  box-shadow: 2px 2px 2px 2px #b6b6b6;
}

.topbar---topbar-toggle---1KRjR .topbar---icon-bar---u1f02 {
  height: 3px !important;
  opacity: 1;
}

.topbar---topbar---_2tBP.topbar---expanded---1KdUL
  .topbar---topbar-toggle---1KRjR
  .topbar---icon-bar---u1f02:nth-child(3) {
  margin-top: -10px !important;
}
.topbar---topbar-toggle---1KRjR {
  height: 4em !important ;
  /* margin-left: 5em; */
  width: 4em !important;
  border: 0px solid transparent !important;
  border-radius: 50% !important;
  margin: 15px 5px !important;
  background: rgba(255, 255, 255, 0.2) !important;
}

.topbar---navicon---3gCRo {
  opacity: 1 !important;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* .topbar---topbar-nav---3tvij{
      margin-top:1em !important;
    } */

/* Style Audio Tag */

audio {
  border: #292323 2px solid;
  border-radius: 2%;
}

.topbar-header-nav {
  padding-left: 90px !important;
  padding: 5px;
  /* display: block; */
  background: #eaeaea;
  color: #000000;
  padding-left: 260px;
  /* position: fixed; */
  z-index: 1;
  width: 100%;
  /* background: #fafafa; */
  margin: 0px !important;
  font-family: "Bree Serif", serif !important;
}

.wrapper {
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

#sidebar {
    width: 70px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    background: #0747a6;
    color: #fff;
    transition: all 0.3s;
}

#sidebar {
    min-width: 70px;
    max-width: 250px;
}

#sidebar.active {
    margin-left: -250px;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}


body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 8px;
}

#sidebar {
 
    background: #0747a6;
    color: #fff;
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #0747a6;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: #fff;
}
#sidebar ul li a:hover {
    color: #fff;
    background: rgb(53, 90, 236);
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #042e6d;
}
ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
}


.topHeader{
    margin: 0 auto; 
     position: absolute !important;
    width: 165vh;
    top: 10px;
    right: 10px;
    
}
.topHeaderTitle{
    padding: 10px;
} 


.wrapper {
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

#sidebar {
    
  
    height: 100vh;
    z-index: 999;
    background: #1A73E8;
    color: #fff;
    transition: all 0.3s;
}



#sidebar.active {
    margin-left: -250px;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.dropdown-container{
margin:0 30px 0 30px;
font-size: 14px;
}


body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 8px;
}

#sidebar {
    /* don't forget to add all the previously mentioned styles here too */
    background: #0747a6;
    color: #fff;
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #0747a6;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: #fff;
}
#sidebar ul li a:hover {
    color: #fff;
    background: rgb(53, 90, 236);
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #042e6d;
}
ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
}


.topHeader{
    /* margin: 0 auto; */
    position: absolute !important;
    width: 165vh;
    top: 10px;
    right: 10px;
    
}
.topHeaderTitle{
    padding: 10px;
}
.popup{
visibility: hidden;
width: 120px;
background-color: black;
color: #fff !important;
text-align: center;
border-radius: 6px;
display: inline-block;
padding: 4px 8px;
font-size: 14px;


/* Position the tooltip */
position: absolute;
z-index: 1;
}

.main:hover .popup {
visibility: visible;
}
.testtt{
height:0px ;
}
/* width
.transcripts::-webkit-scrollbar {
  width: 10px;
}

/* Track */
/* .transcripts::-webkit-scrollbar-track {
  background: #f1f1f1; 
} */
 
/* Handle */
/* .transcripts::-webkit-scrollbar-thumb {
  background: #888; 
} */

/* Handle on hover */
/* .transcripts::-webkit-scrollbar-thumb:hover {
  background: #555; 
}  */

















.homewrapper {
    margin-top: 1em;
  }
  .dashboardParent {
    width: 100%;
    /* height: 100%; */
    position: relative;
    /* padding-left: 0px; */
    /* padding-top: 10px; */
    background: #fafafa;
  
    margin: auto;
  
    left: 0;
    right: 0;
    /* top: 55px; */
    bottom: 0;
  }
  .spanWord span{
   display: none;
  }
  .spanWord:hover span{
    display: inline-block;
   }
   .eachWordSpan span{
    display: none;
   }
   .eachWordSpan:hover span{
    display: inline-block;
   }

  /* Accordian */
  .accordian{
    position: absolute;
    width: 17%;
    margin-left: 56px;
  }
  

  /* Video Container */
  .videoContainer{
    margin-left: 244px;
  }

  /* No Video */
  .noVideo{
    height: 100vh;
    background-color: #fafafa;
    display: -webkit-flex;
    display: flex;
    /* align-items: center; */
    -webkit-justify-content: center;
            justify-content: center;
    margin-left: 40px;
  }
  .card-header-sub {
    overflow: hidden;
    /* border-bottom: 1px solid #b6b6b6;
    border-right: 1px solid #b6b6b6;
    border-left: 1px solid #b6b6b6; */
    clear: both;
    background-color: #ffffff;
    padding: 0.6rem 0.25rem !important;
    cursor: pointer;
  }
  
  .top-header-category {
    overflow: hidden;
    border-bottom: 1px solid #d3d3d3;
    border-top: 1px solid #d3d3d3;
    clear: both;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
  .homeboxCardViewTop {
    background-color: #ffffff;
    text-align: center;
    float: left;
    /* // box-shadow: 0 3px 4px 0 rgba(0,0,0,.2), 0 3px 3px -2px rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12); */
    border: 1px solid #b6b6b6;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: black;
  }
  
  .card-header-sub:hover {
    background-color: #d3d3d3;
  }
  .loadingBarCss {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .table-cell-css {
    display: table-cell;
    vertical-align: middle;
  }
  .tableCss {
    display: table;
    width: 100%;
    height: 90%;
  }
  .ContactPage {
    padding: 10px 15px;
  }
  .ContactPage .makeStyles-root-1.form-group,.ContactPage .form-group{display:inline-block;width:49%;margin:.5%;}
  .ContactPage .txtAreaContct.makeStyles-root-1.form-group,.ContactPage .txtAreaContct.form-group{width:98%;}
  .ContactPage .txtAreaContct.makeStyles-root-1.form-group,.ContactPage .txtAreaContct.form-group {width:98%;}
  .page-title-section,
  .page-id-70 .page-title-section {
    padding: 25px 0;
  }
 .warn{
    color: red !important;
    font-size: 0.9em !important;
    margin-bottom:0 !important;
    margin-left:25px ;
 }
  
  .contactForm .card-body {
    padding: 1.4rem 0.5rem 0.5rem 2.5rem !important;
    display: inline-block;
  }
  
  .contactForm .card-title {
    text-align: center;
    color: #2a83e8;
    font-size: 2rem !important ;
  }
  .ContactPage .MuiFormControl-root,.formParent .rightDiv{background-color: #fff;background: #ffffff;}    
  .videoplayer{
    height: 450px;
    width: 800px;
    margin: 30px 70px 30px 70px;
    border:black solid 2px;
  }
  .Transcript{
    margin:0 70px 0 70px;
    border-top: #b6b6b6 solid 1px;
    width: 100%;
  }
    .user{
   
    
    /* padding:4px; */
    
   
    display: -webkit-flex;
    
   
    display: flex;
    
    -webkit-flex-direction: row;
    
            flex-direction: row;
  }
  .userDetails{
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-inline-flex;
    display: inline-flex;
    
  }
  .userImg{
    margin-right: 16px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: transparent;
    overflow: hidden;
  }
  .userName{
    padding-top: 5px;
    font-size: 1rem;
    -webkit-flex: 1;
    flex: 1 1;
    -webkit-flex-basis: 1e-9px;
            flex-basis: 1e-9px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;

  }
  .transcripts{
    border: rgba(128, 128, 128, 0.822) solid 2px;
    margin: 0px 140px 30px 150px;
    height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;


  }
  .titlee{
    font-weight: 500;
  }
  .paras{
    padding-left: 60px;
    color: #000000 !important;
    /* border-bottom:#000000 solid 2px; */
    /* padding-bottom: 40px; */
    /* padding-top: 5px; */
  }
  .Transcript_all{
    /* margin: 0 0 0 66px; */
    
    /* border-top: #B6B6B7 solid 1px; */
    padding: 10px;
    width: 94%;
  }
  p{
    color: black !important;
  }

  input[type=checkbox], input[type=radio] {
    margin: 10px;
   
    box-sizing: border-box;
    padding: 0;
    width: 20px;
    height: 20px;
}
input:checked{
  background-color: #f50057;

}
mark{
  background-color: rgba(252, 148, 3,0.7);
}
.highlightTomato{
  /* padding:1px; */
  background-color:  rgba(252, 148, 3,0.7);
}
.spanWord span{
  display: none;
 }
 .spanWord:hover span{
   display: inline-block;
  }
  .red{
    background-color:rgba(244, 248, 28, 0.7);
  }
  .selectedHighlight{
    border:1px solid black;
    width: 70%;
    margin: auto;
    margin-bottom: 5px;
    font-size: 12px;
    padding: 5px;
    box-shadow:2px 2px 2px rgba(128, 128, 128, 0.822);
  }
/* Popup style */
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
  }
  
  .box {
    position: relative;
    width: 25%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    
  }
  .groupp{
    padding: 10px;
    cursor: pointer;
  }

  .groupp:hover{
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem #0747a6a1;
  }
  
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(39% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
td{
    padding: 30px 5px 5px 100px !important;
    text-align: left !important;
}
.card{
    margin:50px 50px 50px 100px;
    border-radius: 20px;
}






/*** FONTS ***/
/*** VARIABLES ***/
/* Colors */
/*** EXTEND ***/
/* box-shadow */
ol.gradient-list > li, ol.gradient-list > li::before {
    box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
}
ol.gradient-list > li:hover, ol.gradient-list > li:hover {
    box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem #0747a6a1;
}
/*** STYLE ***/
*, *:before, *:after {
    box-sizing: border-box;
}
main {
    display: block;
    margin: 0 auto;
    /* max-width: 40rem; */
    padding: 0.4rem 1rem;
}
ol.gradient-list {
    counter-reset: gradient-counter;
    list-style: none;
    margin: 1.75rem 0;
    padding-left: 1rem;
}
ol.gradient-list > li {
    background: white;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    counter-increment: gradient-counter;
    margin-top: 1rem;
    min-height: 3rem;
    padding: 1rem 1rem 1rem 3rem;
    position: relative;
}
ol.gradient-list > li::before, ol.gradient-list > li::after {
    background: linear-gradient(135deg, #0747a6 0%, #000000 100%);
    border-radius: 1rem 1rem 0 1rem;
    content: '';
    height: 3rem;
    left: -1rem;
    overflow: hidden;
    position: absolute;
    top: -1rem;
    width: 3rem;
}
ol.gradient-list > li::before {
    -webkit-align-items: flex-end;
            align-items: flex-end;
    /* content: counter(gradient-counter); */
    color: #1d1f20;
    display: -webkit-flex;
    display: flex;
    font: 900 1.5em/1 'Montserrat';
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 0.125em 0.25em;
    z-index: 1;
}

ol.gradient-list > li:nth-child(10n+1):before {
    background-image: url('https://storage.googleapis.com/fargowiz/Webp.net-resizeimage%20(2).png');
    background-repeat:no-repeat ;
    background-position: center;   /* background:linear-gradient(135deg, rgb(7 71 166) 0%, rgb(0 0 0) 100%); */
}
ol.gradient-list > li:nth-child(10n+2):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.4) 0%, rgba(253, 220, 50, 0.4) 100%);
}
ol.gradient-list > li:nth-child(10n+3):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.6) 0%, rgba(253, 220, 50, 0.6) 100%);
}
ol.gradient-list > li:nth-child(10n+4):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.8) 0%, rgba(253, 220, 50, 0.8) 100%);
}
ol.gradient-list > li:nth-child(10n+5):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 1) 0%, rgba(253, 220, 50, 1) 100%);
}
ol.gradient-list > li:nth-child(10n+6):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.8) 0%, rgba(253, 220, 50, 0.8) 100%);
}
ol.gradient-list > li:nth-child(10n+7):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.6) 0%, rgba(253, 220, 50, 0.6) 100%);
}
ol.gradient-list > li:nth-child(10n+8):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.4) 0%, rgba(253, 220, 50, 0.4) 100%);
}
ol.gradient-list > li:nth-child(10n+9):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0.2) 0%, rgba(253, 220, 50, 0.2) 100%);
}
ol.gradient-list > li:nth-child(10n+10):before {
    background: linear-gradient(135deg, rgba(162, 237, 86, 0) 0%, rgba(253, 220, 50, 0) 100%);
}
ol.gradient-list > li + li {
    margin-top: 2rem;
}

.transResults {
  border: rgba(128, 128, 128, 0.822) solid 2px;
  margin: 0px 40px 30px 150px;
  padding: 20px;
  background-color: white;
  /* height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden; */
}
.transResults {
  border: rgb(219 219 219) solid 2px;
  margin: 0px 40px 30px 150px;
  padding: 20px;
  background-color: white;
  /* height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden; */
}
.transQ {
  font-size: 0.9em;
  margin-bottom: 0px !important;
  margin-left: 51px !important;
}
.eachTrans {
  /* box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05); */
  padding: 10px;
}
.eachTrans:hover {
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05),
    0 0.5rem 1.125rem #0747a6a1;
  /* box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05); */
}
.searchButton {
  border-radius: 4px !important;
  background-color: #ffffff;
  border-width: 1px;
  margin-left: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.searchButton:hover {
  border: gray solid 1px;
  color: black;
  background-color: rgba(128, 128, 128, 0.822);
}
mark {
  background-color: rgba(252, 148, 3, 0.7);
}
.highlightstopbar #sectionTop .topbar-header-nav {
  padding-left: 0px !important;
}

.trimmedVideodiv{
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 16px;
    padding: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.trimmedTranscript{
    margin-top: 10px;
    padding: 3px;
}
.trimmedVideotitle{
    text-align: center;
    text-decoration: underline;
    text-transform: capitalize;
}
