/* Popup style */
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
  }
  
  .box {
    position: relative;
    width: 25%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    
  }
  .groupp{
    padding: 10px;
    cursor: pointer;
  }

  .groupp:hover{
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem #0747a6a1;
  }
  
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(39% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }