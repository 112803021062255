/* width
.transcripts::-webkit-scrollbar {
  width: 10px;
}

/* Track */
/* .transcripts::-webkit-scrollbar-track {
  background: #f1f1f1; 
} */
 
/* Handle */
/* .transcripts::-webkit-scrollbar-thumb {
  background: #888; 
} */

/* Handle on hover */
/* .transcripts::-webkit-scrollbar-thumb:hover {
  background: #555; 
}  */

















.homewrapper {
    margin-top: 1em;
  }
  .dashboardParent {
    width: 100%;
    /* height: 100%; */
    position: relative;
    /* padding-left: 0px; */
    /* padding-top: 10px; */
    background: #fafafa;
  
    margin: auto;
  
    left: 0;
    right: 0;
    /* top: 55px; */
    bottom: 0;
  }
  .spanWord span{
   display: none;
  }
  .spanWord:hover span{
    display: inline-block;
   }
   .eachWordSpan span{
    display: none;
   }
   .eachWordSpan:hover span{
    display: inline-block;
   }

  /* Accordian */
  .accordian{
    position: absolute;
    width: 17%;
    margin-left: 56px;
  }
  

  /* Video Container */
  .videoContainer{
    margin-left: 244px;
  }

  /* No Video */
  .noVideo{
    height: 100vh;
    background-color: #fafafa;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin-left: 40px;
  }
  .card-header-sub {
    overflow: hidden;
    /* border-bottom: 1px solid #b6b6b6;
    border-right: 1px solid #b6b6b6;
    border-left: 1px solid #b6b6b6; */
    clear: both;
    background-color: #ffffff;
    padding: 0.6rem 0.25rem !important;
    cursor: pointer;
  }
  
  .top-header-category {
    overflow: hidden;
    border-bottom: 1px solid #d3d3d3;
    border-top: 1px solid #d3d3d3;
    clear: both;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
  .homeboxCardViewTop {
    background-color: #ffffff;
    text-align: center;
    float: left;
    /* // box-shadow: 0 3px 4px 0 rgba(0,0,0,.2), 0 3px 3px -2px rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12); */
    border: 1px solid #b6b6b6;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: black;
  }
  
  .card-header-sub:hover {
    background-color: #d3d3d3;
  }
  .loadingBarCss {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .table-cell-css {
    display: table-cell;
    vertical-align: middle;
  }
  .tableCss {
    display: table;
    width: 100%;
    height: 90%;
  }
  .ContactPage {
    padding: 10px 15px;
  }
  .ContactPage .makeStyles-root-1.form-group,.ContactPage .form-group{display:inline-block;width:49%;margin:.5%;}
  .ContactPage .txtAreaContct.makeStyles-root-1.form-group,.ContactPage .txtAreaContct.form-group{width:98%;}
  .ContactPage .txtAreaContct.makeStyles-root-1.form-group,.ContactPage .txtAreaContct.form-group {width:98%;}
  .page-title-section,
  .page-id-70 .page-title-section {
    padding: 25px 0;
  }
 .warn{
    color: red !important;
    font-size: 0.9em !important;
    margin-bottom:0 !important;
    margin-left:25px ;
 }
  
  .contactForm .card-body {
    padding: 1.4rem 0.5rem 0.5rem 2.5rem !important;
    display: inline-block;
  }
  
  .contactForm .card-title {
    text-align: center;
    color: #2a83e8;
    font-size: 2rem !important ;
  }
  .ContactPage .MuiFormControl-root,.formParent .rightDiv{background-color: #fff;background: #ffffff;}    
  .videoplayer{
    height: 450px;
    width: 800px;
    margin: 30px 70px 30px 70px;
    border:black solid 2px;
  }
  .Transcript{
    margin:0 70px 0 70px;
    border-top: #b6b6b6 solid 1px;
    width: 100%;
  }
    .user{
   
    
    /* padding:4px; */
    
   
    display: flex;
    
    flex-direction: row;
  }
  .userDetails{
    flex: 1;
    display: inline-flex;
    
  }
  .userImg{
    margin-right: 16px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: transparent;
    overflow: hidden;
  }
  .userName{
    padding-top: 5px;
    font-size: 1rem;
    -ms-flex: 1 1 1e-9px;
    -webkit-flex: 1;
    flex: 1;
    -webkit-flex-basis: 1e-9px;
    flex-basis: 1e-9px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;

  }
  .transcripts{
    border: rgba(128, 128, 128, 0.822) solid 2px;
    margin: 0px 140px 30px 150px;
    height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;


  }
  .titlee{
    font-weight: 500;
  }
  .paras{
    padding-left: 60px;
    color: #000000 !important;
    /* border-bottom:#000000 solid 2px; */
    /* padding-bottom: 40px; */
    /* padding-top: 5px; */
  }
  .Transcript_all{
    /* margin: 0 0 0 66px; */
    
    /* border-top: #B6B6B7 solid 1px; */
    padding: 10px;
    width: 94%;
  }
  p{
    color: black !important;
  }

  input[type=checkbox], input[type=radio] {
    margin: 10px;
   
    box-sizing: border-box;
    padding: 0;
    width: 20px;
    height: 20px;
}
input:checked{
  background-color: #f50057;

}
mark{
  background-color: rgba(252, 148, 3,0.7);
}
.highlightTomato{
  /* padding:1px; */
  background-color:  rgba(252, 148, 3,0.7);
}
.spanWord span{
  display: none;
 }
 .spanWord:hover span{
   display: inline-block;
  }
  .red{
    background-color:rgba(244, 248, 28, 0.7);
  }
  .selectedHighlight{
    border:1px solid black;
    width: 70%;
    margin: auto;
    margin-bottom: 5px;
    font-size: 12px;
    padding: 5px;
    box-shadow:2px 2px 2px rgba(128, 128, 128, 0.822);
  }