@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
.wrapper {
  display: block;
  width: 90%;
  align-items: stretch;
}

body {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

#topbar {
  /* don't forget to add all the previously mentioned styles here too */
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  z-index: 9;
  left: 0;
  color: #fff;
  transition: all 0.3s;
  width: 100%;
  margin: auto;
  position: relative;
}

#topbar .topbar-header {
  color: #0e0e0e;
  padding: 8px;
  background: #fafafa;
  /* box-shadow: 5px 5px 7px 1px #888888; */
  border: 1px solid #aeacac;
  box-shadow: 5px 5px 9px 1px #f1ebeb;
}

#topbar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #47748b;
}

#topbar ul p {
  color: #fff;
  padding: 10px;
}

#topbar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}
#topbar ul li a:hover {
  color: #7386d5;
  background: #fff;
}

#topbar .topbar-header h3 {
  text-align: center;
}

#topbar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}
ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}
.fullheight {
  height: 100%;
}

.app {
  width: 50%;
  margin: 0 auto;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  color: #4a4a4d;
  font: 14px/1.4 "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 100%;
}
tr {
  overflow-x: scroll;
}
th,
td {
  padding: 5px 10px;
  vertical-align: middle;
  text-align: center;
}
thead {
  font-size: 14px;
  line-height: 24px;
  font-family: Lato;
  border: 1px solid transparent;
  width: 100%;
  max-width: 100%;
  font-weight: 900;
  line-height: 24px;
  mix-blend-mode: normal;

  color: rgba(51, 51, 51, 0.5);
  background: #f0f0f2;
}
thead tr th {
  padding: 5px 10px;
  border: 1px solid transparent;
  color: #000000;
  position: sticky;
  top: 0;
  position: -webkit-sticky;
  background: #f0f0f2;
  font-family: sans-serif;
  letter-spacing: 1.4px;

  text-align: center;
}
tbody tr {
  cursor: pointer;
}
tbody {
  max-width: 100%;
}
tbody tr:nth-child(even) {
  background: #f0f0f2;
}
tbody tr:nth-child(even) {
  background: #ffffff;
}
td {
  padding: 5px 10px;
}
td:first-child {
}
@media screen and (max-width: 767px) {
  .chartClass {
    width: 90% !important;
    float: none !important;
    margin: 2% auto;
  }
}

@media screen and (max-width: 798px) {
  .peoplecount {
    width: 90% !important;
    float: none !important;
    margin-left: 1em;
  }
}
@media screen and (max-width: 798px) {
  .peoplecountinside {
    text-align: center;
    margin-block-start: 1em !important;
    margin-block-end: 0em !important;
    margin-inline-end: -1em !important;
    width: 100% !important;
  }
}
@media screen and (max-width: 798px) {
  #topbar {
    text-align: center;
  }
}
@media screen and (max-width: 798px) {
  .topbar-header {
    float: none !important;
    padding-top: 30px !important;
  }
}
@media screen and (max-width: 798px) {
  .homedetail {
    text-align: center !important;
  }
}
@media screen and (max-width: 798px) {
  .homedetail-div {
  }
}

/* .homebox1{
  cursor: pointer;
width: 25%;
    background-color: #e2e2e2;
    text-align: center;
    float: left;
    margin-left: 0.6em;
    padding: 5px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.2), 0 3px 3px -2px rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12);
    border-radius: 5px;

} */
.homebox2 {
  width: 25%;
  background-color: #e2e2e2;
  text-align: center;
  float: left;
  margin-left: 0.6em;
  padding: 5px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.homeboxnumber {
  font-weight: bold;
  font-size: 2em;
}

/* @media screen and (max-width: 798px) {
    .homebox1  {
    
      float: none;
      width: 95%;
      margin: 10px auto;
      
      
      
    }
  } */
@media screen and (max-width: 798px) {
  .homebox2 {
    float: none;
    width: 95%;
    margin: 4px auto;
  }
}

@media screen and (max-width: 798px) {
  .main-wrapper {
    padding-top: 104px !important;
  }
}
@media screen and (max-width: 798px) {
  .table-question-option {
    padding-top: 0px !important;
    margin: auto 5% !important;
  }
}

/* .homebox1:hover,.homebox2:hover, .homebox1:active, .homebox2:active, .homebox1:visited, .homebox2:visited{
    background-color: #F0F0F2;
    transition: 0.2s ease-in-out;
  }
    .homebox1:hover .homeboxnumber,.homebox1:hover  .homeboxtitle, .homebox2:hover .homeboxnumber,.homebox2:hover  .homeboxtitle{color:#ffffff;} */

@media screen and (max-width: 502px) {
  .main-wrapper {
    padding-top: 133px !important;
  }
}

.ReactTable {
  box-shadow: 2px 2px 2px 2px #b6b6b6;
}

.topbar---topbar-toggle---1KRjR .topbar---icon-bar---u1f02 {
  height: 3px !important;
  opacity: 1;
}

.topbar---topbar---_2tBP.topbar---expanded---1KdUL
  .topbar---topbar-toggle---1KRjR
  .topbar---icon-bar---u1f02:nth-child(3) {
  margin-top: -10px !important;
}
.topbar---topbar-toggle---1KRjR {
  height: 4em !important ;
  /* margin-left: 5em; */
  width: 4em !important;
  border: 0px solid transparent !important;
  border-radius: 50% !important;
  margin: 15px 5px !important;
  background: rgba(255, 255, 255, 0.2) !important;
}

.topbar---navicon---3gCRo {
  opacity: 1 !important;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* .topbar---topbar-nav---3tvij{
      margin-top:1em !important;
    } */

/* Style Audio Tag */

audio {
  border: #292323 2px solid;
  border-radius: 2%;
}

.topbar-header-nav {
  padding-left: 90px !important;
  padding: 5px;
  /* display: block; */
  background: #eaeaea;
  color: #000000;
  padding-left: 260px;
  /* position: fixed; */
  z-index: 1;
  width: 100%;
  /* background: #fafafa; */
  margin: 0px !important;
  font-family: "Bree Serif", serif !important;
}
