.transResults {
  border: rgba(128, 128, 128, 0.822) solid 2px;
  margin: 0px 40px 30px 150px;
  padding: 20px;
  background-color: white;
  /* height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden; */
}
.transResults {
  border: rgb(219 219 219) solid 2px;
  margin: 0px 40px 30px 150px;
  padding: 20px;
  background-color: white;
  /* height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden; */
}
.transQ {
  font-size: 0.9em;
  margin-bottom: 0px !important;
  margin-left: 51px !important;
}
.eachTrans {
  /* box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05); */
  padding: 10px;
}
.eachTrans:hover {
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05),
    0 0.5rem 1.125rem #0747a6a1;
  /* box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05); */
}
.searchButton {
  border-radius: 4px !important;
  background-color: #ffffff;
  border-width: 1px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchButton:hover {
  border: gray solid 1px;
  color: black;
  background-color: rgba(128, 128, 128, 0.822);
}
mark {
  background-color: rgba(252, 148, 3, 0.7);
}
.highlightstopbar #sectionTop .topbar-header-nav {
  padding-left: 0px !important;
}
